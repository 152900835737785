<template>
  <form>
    <!-- email` -->
    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input
        type="email"
        v-model="user.email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
      />
    </div>

    <!-- password -->
    <div class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <input
        type="password"
        v-model="user.password"
        class="form-control"
        id="exampleInputPassword1"
      />
    </div>

    <!-- submit button -->
    <div class="d-flex justify-content-end">
      <button
        type="submit"
        class="btn btn-primary float-right mt-2"
        @click.prevent="performLogin"
      >
        Submit
      </button>
    </div>
  </form>
</template>
<script>
export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async performLogin() {
      await this.$repos.authRepo.login(this.user);
    },
  },
};
</script>