<template>
  <div>
    <section>
      <div class="section--title">
        <h1>Report by Bank</h1>
        <hr />
      </div>
    </section>

    <section class="mt-5">
      <ReportsTable2 />
    </section>

    <section class="mt-5">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <BankReport />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ReportsTable2 from '@/components/shared/ReportsTable2'
import BankReport from '@/components/tables/Reports/BankReport'

export default {
  components: {
    ReportsTable2,
    BankReport
  }
}
</script>