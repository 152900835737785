<template>
  <div>
    <section>
      <div class="section--title">
        <h1>Manual Payments</h1>
        <hr />
      </div>
    </section>

    <section class="mt-5">
      <FilterTable />
    </section>

    <section class="mt-5">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <ManualPayments />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FilterTable from '@/components/shared/FilterTable'
import ManualPayments from '@/components/tables/flowTables/ManualPayments'

export default {
  components: {
    FilterTable,
    ManualPayments
  }
}
</script>