<template>
<div>
  <div class="d-flex bd-highlight">
  <div class="p-2 w-100 bd-highlight">
      <p>Showing result for record date from Apr 26, 2021 to Apr 26, 2021</p>
  </div>
  <div class="p-2 d-flex flex-md-column flex-shrink-1 bd-highlight">
    <section class="d-flex d-block">
        <p>Copy</p>
        <p class="ml-3">CSV</p>
        <p class="ml-3">Excel</p>
        <p class="ml-3">PDF</p>
        <p class="ml-3">Print</p>
    </section>     
    </div>
  </div>
  <table class="table table-responsive table-bordered">
    <caption></caption>
    <thead>
      <tr>
        <th scope="col">Processed</th>
        <th scope="col">Payment Log ID</th>
        <th scope="col">Customer Ref</th>
        <th scope="col">Customer Name</th>
        <th scope="col">Customer Phone Number</th>
        <th scope="col">Amount 	&#8358; </th>
        <th scope="col">Depositor Name</th>
        <th scope="col">Settlement Date</th>
        <th scope="col">Payment Date</th>
        <th scope="col">Payment Time</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><h6><span class="badge badge-secondary">New</span></h6></td>
        <td>156845717</td>
        <td>--N/A--</td>
        <td>LUGARD UGIAGBE</td>
        <td>08131164790</td>
        <td>5,000.00</td>
        <td>08131164790</td>
        <td>2021-04-27</td>
        <td>2021-04-26</td>
        <td>15:42:12</td>
        <td><button type="button" class="btn btn-success">Success</button></td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
export default {
  data(){
    return{
      
    }
  }
}
</script>