<template>
  <div>
    <section>
      <div class="section--title">
        <h1>Pay Direct Notifications</h1>
        <hr />
      </div>
    </section>

    <section class="mt-5">
      <FilterTable />
    </section>

    <section class="mt-5">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <pay-direct-notification-table />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FilterTable from '@/components/shared/FilterTable'
import PayDirectNotificationTable from '@/components/tables/flowTables/PayDirectNotificationsTable'

export default {
  components: {
    FilterTable,
    PayDirectNotificationTable
  }
}
</script>