import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { apiClient } from "./plugins/axios";
import store from './store'
import repositories from "./repositories/index";

Vue.config.productionTip = false

Vue.prototype.$repos = repositories(apiClient);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
