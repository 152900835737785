<template>
  <div class="d-flex flex-column min-vh-100">
    <section class="flex-grow-1 flex-shrink-1 pb-3 bg-light">
      <header>
        <the-topbar>
          <div class="navbar-nav ml-auto d-flex flex-row user--details">
            <a class="nav-link mr-3" href="#">Oluwatunmise Adenuga</a>
            <a class="nav-link" href="#">LOGOUT</a>
          </div>
        </the-topbar>
        <the-navbar />
      </header>
      <main class="py-5">
        <div class="container">
          <router-view />
        </div>
      </main>
    </section>
    <the-footer />
  </div>
</template>

<script>
import TheTopbar from '@/components/navigation/Topbar'
import TheFooter from '@/components/navigation/Footer'
import TheNavbar from '@/components/navigation/Navbar'

export default {
  name: 'DefaultLayout',
  components: {
    TheTopbar,
    TheFooter,
    TheNavbar
  }
}
</script>


<style lang="scss">
  .user--details {
    a.nav-link {
      color: $primary !important;
      font-weight: 600;
      opacity: 1
    }
  }
</style>