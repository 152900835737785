<template>
  <nav class="navbar navbar-light bg-white">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img 
          src="@/assets/images/nav-logo-one.png" 
          class="mr-3" 
          height="50" 
          alt="ERAS logo"
        >
        <img 
          src="@/assets/images/nav-logo-two.png" 
          height="20"
          alt="ERAS logo"
        >
      </a>
      <slot />
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AuthNav'
}
</script>