<template>
  <div>
    <section>
      <div class="section--title">
        <h1>Today's Stats</h1>
        <hr />
      </div>
    </section>

    <section class="mt-5">
      <div class="row">
        <div class="col-md-4">
          <div class="card shadow-none border-0 analytics--card">
            <div class="analytics--card__title">
              <h6>Total POA</h6>
            </div>
            <hr />
            <div class="analytics--card__amount">
              <h6>₦ 84,325.00</h6>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card shadow-none border-0 analytics--card">
            <div class="analytics--card__title">
              <h6>Total Bill Settlement</h6>
            </div>
            <hr />
            <div class="analytics--card__amount">
              <h6>₦ 84,325.00</h6>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card shadow-none border-0 analytics--card">
            <div class="analytics--card__title">
              <h6>Total Transactions</h6>
            </div>
            <hr />
            <div class="analytics--card__amount">
              <h6>₦ 84,325.00</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-5">
      <div class="section--title">
        <h1>This Week's Stats</h1>
        <hr />
      </div>
      <div class="row mt-5">
        <div class="col-md-6 h-100 mb-3 mb-md-0">
          <div class="card shadow-none border-0">
            <div class="card-body">
              <div class="table--caption">
                <h3>This Week's Stats</h3>
                <hr />
              </div>
              <bar-chart :chart-data="datacollection" :options="options"></bar-chart>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card shadow-none border-0">
            <div class="card-body">
              <div class="table--caption">
                <h3>This Week's Stats</h3>
                <hr />
              </div>
              <dashboard-weekly-stats />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import DashboardWeeklyStats from '@/components/tables/DashWeeklyStats';
import BarChart from "@/charts/BarChart.js";
export default {
  components: {
    DashboardWeeklyStats,
    BarChart
  },
  data() {
    return {
       datacollection: {
        labels: [
          "Airtime Recharge",
          "Wallet to Wallet",
          "Bill Payment",
          "Wallet to Bank",
        ],
        datasets: [
          {
            label: "Merchant Wallet",
            backgroundColor: "#f87979",
            data: [0, 0, 0, 0],
          },
          {
            label: "Customer Wallet",
            backgroundColor: "#00ffdd",
            data: [0, 0, 0, 0],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
}
</script>
<style lang="scss">
  .section--title {
    h1 {
      color: $secondary;
      font-weight: bold;
      font-size: 20px;
      margin-top: 0px;
      margin-bottom: 15px;
    }
    hr {
      width: 40%;
      display: block;
      border-top: 1px solid $primary;
      margin-left: 0px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }

  .analytics--card {
    padding: 15px;
    &__title {
      h6 {
        color: $secondary;
        font-weight: 700;
        font-size: 16px;
      }
    }
    hr {
      border: 1px solid #fafafa;
    }
    &__amount {
      font-size: 14px;
      font-weight: bold;
      color: $black;
    }
  }

  .table--caption {
    margin-bottom: 30px;
    h3 {
      color: $secondary;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
    }
  }
</style>