<template>
<div>
  <div class="d-flex bd-highlight">
    <div class="p-2 w-100 bd-highlight">
      <p>Report Result</p>
    </div>
    <div class="p-2 d-flex flex-md-column flex-shrink-1 bd-highlight">
      <section class="d-flex d-block">
        <p>Copy</p>
        <p class="ml-3">CSV</p>
        <p class="ml-3">Excel</p>
        <p class="ml-3">PDF</p>
        <p class="ml-3">Print</p>
      </section>     
    </div>
  </div>
  <table class="table table-responsive table-bordered">
    <caption></caption>
    <thead>
      <tr>
        <th scope="col">S/N</th>
        <th scope="col">Payer Name</th>
        <th scope="col">Payment Log ID</th>
        <th scope="col">Original Payment Log ID</th>
        <th scope="col">Payment Reference</th>
        <th scope="col">Original Payment Reference</th>
        <th scope="col">Amount</th>
        <th scope="col">Reversal Date</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>156845717</td>
        <td>--N/A--</td>
        <td>LUGARD UGIAGBE</td>
        <td>08131164790</td>
        <td>5,000.00</td>
        <td>08131164790</td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
export default {
  data(){
    return{
      
    }
  }
}
</script>