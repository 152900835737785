import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

// import layouts
import AuthLayout from '@/layouts/AuthLayout'
import DefaultLayout from '@/layouts/DefaultLayout'

// import auth paths
import Login from '@/views/auth/Login'

// flowtables
import PayDirectNotifications from '@/views/flowTables/PayDirectNotifications'
import PreServiceBills from '@/views/flowTables/PreServiceBills'
import CBSPoa from '@/views/flowTables/CBSPoa'
import ManualPayments from '@/views/flowTables/ManualPayments'
import BillSettlement from '@/views/flowTables/BillSettlement'
import Unprocessed from '@/views/flowTables/Unprocessed'
import CBSQueue from '@/views/flowTables/CBSQueue'
import CBSPaydirect from '@/views/flowTables/CBSPaydirect'

// Reports
import BankReport from '@/views/Reports/BankReport'
import GeneralReport from '@/views/Reports/GeneralReport'
import MonthlyReport from '@/views/Reports/MonthlyReport'
import RevenueReport from '@/views/Reports/RevenueReport'
import ReversedReport from '@/views/Reports/ReversedReport'
import YearlyReport from '@/views/Reports/YearlyReport'

// import admin routes
import Dashboard from '@/views/dashboard/Dashboard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: AuthLayout,
    children: [
      {
        path: '/',
        component: Login,
        name: 'login'
      },
    ]
  },
  {
    path: '/admin', component: DefaultLayout,
    children: [
      {
        path: 'dashboard',
        component: Dashboard,
        name: 'dashboard'
      },

      // flow tables start
      {
        path: 'paydirect-notifications',
        component: PayDirectNotifications,
        name: 'PaydirectNotifications'
      },
      {
        path: 'preservicebills',
        component: PreServiceBills,
        name: 'PreServiceBills'
      },
      {
        path: 'cbspoa',
        component: CBSPoa,
        name: 'CBSPoa'
      },
      {
        path: 'manualpayments',
        component: ManualPayments,
        name: 'ManualPayments'
      },
      {
        path: 'billsettlement',
        component: BillSettlement,
        name: 'BillSettlement'
      },
      {
        path: 'unprocessed',
        component: Unprocessed,
        name: 'Unprocessed'
      },
      {
        path: 'cbsqueue',
        component:CBSQueue,
        name: 'CBSQueue'
      },
      {
        path: 'cbspaydirect',
        component: CBSPaydirect,
        name: 'CBSPaydirect'
      },
      // flow tables end

      // Reports
      {
        path: 'bankreport',
        component: BankReport,
        name: 'BankReport'
      },
      {
        path: 'generalreport',
        component: GeneralReport,
        name: 'GeneralReport'
      },
      {
        path: 'monthlyreport',
        component: MonthlyReport,
        name: 'MonthlyReport'
      },
      {
        path: 'revenuereport',
        component: RevenueReport,
        name: 'RevenueReport'
      },
      {
        path: 'reversedreport',
        component:ReversedReport,
        name: 'ReversedReport'
      },
      {
        path: 'yearlyreport',
        component: YearlyReport,
        name: 'YearlyReport'
      },
      // Reports end
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
