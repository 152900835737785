<template>
  <div>
    <section>
      <div class="section--title">
        <h1>Monthly Collections Report</h1>
        <hr />
      </div>
    </section>

    <section class="mt-5">
      <ReportsTable1 />
    </section>

    <section class="mt-5">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <MonthlyReport />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ReportsTable1 from '@/components/shared/ReportsTable1'
import MonthlyReport from '@/components/tables/Reports/MonthlyReport'

export default {
  components: {
    ReportsTable1,
    MonthlyReport
  }
}
</script>